import React from "react";
import styled from "styled-components";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";
import TechnologyCard from "../components/Technology/technologyCard";
import Seo from "../components/SEO";
import Started from "../components/Layout/started";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as styles from "./technology.module.less";

const Title = styled.span`
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 15px;
  @media screen and (max-width: 1200px) {
    font-size: 26px;
  }
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
    br {
      display: none;
    }
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.88;
  letter-spacing: 0.02px;
  color: rgba(36, 45, 65, 0.7);
  padding: 20px 0;
  @media screen and (max-width: 576px) {
    font-size: 14px;
  }
`;

const Technology = (props) => {
  return (
    <Layout>
      <Seo
        title="Technology"
        description="At TrustFlight we believe strongly in the ability of technology to transform safety and efficiency in aviation. But technology in and of itself isn't enough, it takes a carefully crafted user experience to create a compelling product that really unlocks the potential of your aircraft's data. And that's exactly what we obsess about!"
      />
      <PageHeader
        header="Technology"
        subHeader="At TrustFlight we believe strongly in the ability of technology to transform safety and efficiency in aviation. But technology in and of itself isn't enough, it takes a carefully crafted user experience to create a compelling product that really unlocks the potential of your aircraft's data. And that's exactly what we obsess about!"
      />
      <Container>
        <Row className="justify-content-md-center">
          <Col xl={12} lg={12}>
            {props.data.allMarkdownRemark.edges.map((edge, key) => (
              <Row className={styles.markdownCard}>
                <Col xl={5} md={5} className={styles.markdownIcon}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn={
                      key % 2 === 0 ? "animate__fadeIn" : "animate__fadeIn"
                    }
                    offset={0}
                    delay={150}
                  >
                    <img
                      src={edge.node.frontmatter.icon.publicURL}
                      alt="icon"
                    />
                  </AnimationOnScroll>
                </Col>
                <Col xl={7} md={7}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn={
                      key % 2 === 0 ? "animate__fadeIn" : "animate__fadeIn"
                    }
                    offset={0}
                  >
                    <Title>{edge.node.frontmatter.title}</Title>
                    <Text
                      dangerouslySetInnerHTML={{ __html: edge.node.html }}
                    />
                  </AnimationOnScroll>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row
          className={`justify-content-md-center ${styles.technologyCardTitle}`}
        >
          <Col md={12}>
            <AnimationOnScroll
              animateOnce
              animateIn="animate__fadeInDown"
              offset={0}
            >
              <Title>
                What makes our technology stack unique <br />
                in the aviation industry
              </Title>
            </AnimationOnScroll>
          </Col>
          <Col lg={12} md={12} className={styles.techCardWrapper}>
            <TechnologyCard />
          </Col>
        </Row>
        <Started />
      </Container>
    </Layout>
  );
};
export default Technology;

export const query = graphql`
  query TechnologyQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: {
        frontmatter: {
          title: {
            in: [
              "Connecting you with your data"
              "Our technology stack"
              "Integrations"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            icon {
              extension
              publicURL
            }
          }
          html
        }
      }
    }
  }
`;
