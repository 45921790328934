import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { graphql, StaticQuery } from "gatsby";
import { AnimationOnScroll } from "react-animation-on-scroll";
import * as styles from "./technologyCard.module.less";

const TECHNOLOGY_QUERY = graphql`
  query TechQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: {
        frontmatter: { title: { in: ["Modern", "Intuitive", "Secure"] } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            icon {
              extension
              publicURL
            }
          }
          html
        }
      }
    }
  }
`;
const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  padding: 40px 25px;
  box-shadow: 0 2px 4px 0 #ededed;
  background-color: #fcfcfc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    text-align: center;
    align-items: center;
  }
`;

const Header = styled.span`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.02px;
  color: #242d41;
  margin-bottom: 16px;
`;

const Text = styled.div`
  font-size: 16px;
  color: rgba(36, 45, 65, 0.7);
  letter-spacing: 0.02px;
  font-weight: 300;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const TechnologyCard = () => {
  return (
    <Row>
      <StaticQuery
        query={TECHNOLOGY_QUERY}
        render={({ allMarkdownRemark }) =>
          allMarkdownRemark.edges.map((edge, key) => (
            <Col key={key} md={4} className={styles.cardCol}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeIn"
                offset={0}
                delay={key * 150}
              >
                <CardWrapper>
                  <img
                    src={edge.node.frontmatter.icon.publicURL}
                    alt="icon"
                    className={styles.icon}
                  />
                  <Header>{edge.node.frontmatter.title}</Header>
                  <Text dangerouslySetInnerHTML={{ __html: edge.node.html }} />
                </CardWrapper>
              </AnimationOnScroll>
            </Col>
          ))
        }
      />
    </Row>
  );
};

export default TechnologyCard;
